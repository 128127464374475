<template>
  <div>
      <a-row :gutter="16">
      <a-col :span="5">
        <div class="card">
          <div class="card-header card-header-flex">
             <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{menuName}}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
          </div>
        </div>
      </a-col>
      </a-row>
  </div>
</template>

<script>
import Menu from '@/components/app/MenuV2'
// import thousandPrice from '@/helpers/thousandPrice'
// import moment from 'moment'
// import VueChartist from 'v-chartist'
const menus = [
  {
    key: 2,
    sub: true,
    menu: 'Penggajian',
    children: [
      {
        key: 3,
        menu: 'Ringkasan Gaji',
      },
      {
        key: 4,
        menu: 'Riwayat Gaji',
      },
    ],
  },
  {
    key: 6,
    menu: 'Pinjaman',
    icon: 'wallet',
  },
]
export default {
  components: {
    Menu,
  },
  data() {
    return {
      menus,
      menuActive: 1,
    }
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
  },
  computed: {
    menuName() {
      let menu = ''
      this.menus.forEach(el => {
        if (el.sub) {
          const find = el.children.filter(elChildren => elChildren.key === this.menuActive)[0]
          if (find !== undefined) {
            menu = find.menu
          }
        } else {
          if (el.key === this.menuActive) {
            menu = el.menu
          }
        }
      })
      return menu
      // const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      // const { menu } = this.menus.filter(el => el.children ? el.children.filter(elChildren => elChildren.key === this.menuActive)[0] : el.key === this.menuActive)[0]
      // return menu
    },
  },
}
</script>

<style>

</style>
