<template>
  <div>
    <!-- <h2>Dasbor</h2> -->
    <a-row :gutter="16">
      <a-col :span="5">
        <div class="card">
          <div class="card-header card-header-flex">
             <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <div v-if="menuActive === 3" class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{menuName}}</h2>
              </div>
              <div>
                <a-radio-group :default-value="selectLogKehadiranType" button-style="solid" @change="(e) => handleSelectLogKehadiranType(e.target.value)">
                  <a-radio-button value="calendar">
                    Calendar
                  </a-radio-button>
                  <a-radio-button value="table">
                    Table
                  </a-radio-button>
                </a-radio-group>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
            <template v-if="menuActive === 1">
              <ringkasan />
            </template>
            <template v-if="menuActive === 3">
              <kegiatan :displayType="selectLogKehadiranType" />
            </template>
            <template v-if="menuActive === 4">
              <pengajuan-izin />
            </template>
            <template v-if="menuActive === 5">
              <pengajuan-lembur />
            </template>
            <template v-if="menuActive === 6">
              <pengajuan-cuti />
            </template>
          </div>
        </div>
        <div v-else class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{menuName}}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
            <template v-if="menuActive === 1">
              <ringkasan />
            </template>
            <template v-if="menuActive === 3">
              <kegiatan />
            </template>
            <template v-if="menuActive === 4">
              <pengajuan-izin />
            </template>
            <template v-if="menuActive === 5">
              <pengajuan-lembur />
            </template>
            <template v-if="menuActive === 6">
              <pengajuan-cuti />
            </template>
            <template v-if="menuActive === 8">
              <DaftarSlipGajiPegawai :dataPegawai="dataPegawai" />
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
      <!-- <a-col :xs="24" :lg="14">
        <today-attendance />
      </a-col> -->
  </div>
</template>

<script>
const Menu = () => import('@/components/app/MenuV2')
const Ringkasan = () => import('@/views/Pegawai/Ringkasan')
const Kegiatan = () => import('@/views/Pegawai/Kegiatan')
const PengajuanIzin = () => import('@/views/Pegawai/PengajuanIzin')
const PengajuanLembur = () => import('@/views/Pegawai/PengajuanLembur')
const PengajuanCuti = () => import('@/views/Pegawai/PengajuanCuti')
const DaftarSlipGajiPegawai = () => import('@/views/Pegawai/DaftarSlipGajiPegawai')
// import TodayAttendance from '@/components/app/Dashboard/TodayAttendance'
const menus = [
  {
    key: 1,
    menu: 'Dasbor',
  },
  {
    key: 2,
    sub: true,
    menu: 'Kehadiran',
    children: [
      {
        key: 3,
        menu: 'Log Kehadiran Saya',
      },
      {
        key: 4,
        menu: 'Pengajuan Izin',
      },
      {
        key: 5,
        menu: 'Pengajuan Lembur',
      },
      {
        key: 6,
        menu: 'Pengajuan Cuti',
      },
    ],
  },
  {
    key: 7,
    menu: 'Keuangan',
    sub: true,
    children: [
      {
        key: 8,
        menu: 'Slip Gaji',
      },
    ],
  },
]
export default {
  components: {
    // TodayAttendance,
    Menu,
    Ringkasan,
    Kegiatan,
    PengajuanIzin,
    PengajuanLembur,
    PengajuanCuti,
    DaftarSlipGajiPegawai,
  },
  data() {
    return {
      menus,
      selectLogKehadiranType: 'calendar',
      menuActive: 1,
      dataPegawai: {},
    }
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
    handleSelectLogKehadiranType(val) {
      this.selectLogKehadiranType = val
    },
  },
  computed: {
    menuName() {
      let menu = ''
      this.menus.forEach(el => {
        if (el.sub) {
          const find = el.children.filter(elChildren => elChildren.key === this.menuActive)[0]
          if (find !== undefined) {
            menu = find.menu
          }
        } else {
          if (el.key === this.menuActive) {
            menu = el.menu
          }
        }
      })
      return menu
      // const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      // const { menu } = this.menus.filter(el => el.children ? el.children.filter(elChildren => elChildren.key === this.menuActive)[0] : el.key === this.menuActive)[0]
      // return menu
    },
    user() { return this.$store.state.user.user },
  },
  created() {
    this.$store.dispatch('adminHR/GET_PEGAWAI_BY_ID', { id: this.user.id })
      .then(({ data: pegawai }) => {
        this.dataPegawai = pegawai
      })
  },
}
</script>

<style>

</style>
